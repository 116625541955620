
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f0f2f5;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.App {
  text-align: center;
  padding: 20px;
}

/* Light Theme */
.App.light {
  background-color: #f0f2f5;
  color: #333;
}

/* Dark Theme */
.App.dark {
  background-color: #1e272e;
  color: #d2dae2;
}

.App.dark input,
.App.dark button {
  background-color: #485460;
  color: #d2dae2;
  border: 1px solid #808e9b;
}

.App.dark input[type="text"]:focus,
.App.dark input[type="date"]:focus {
  border-color: #00a8ff;
  box-shadow: 0 0 10px rgba(0, 168, 255, 0.3);
}

.App.dark .apod-container {
  background-color: #2f3640;
  border-color: #485460;
}

.App.dark .apod-container h2,
.App.dark .apod-container p {
  color: #ffffff;  /* text should be visible during the dark mode */
}

.App.dark .error-message {
  background-color: #ff4d4d;
  border-color: #ff6b6b;
  color: #fff;
}

/* Header */
h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #2c3e50;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.App.dark h1 {
  color: #ffffff; /*  title should be  in white colour during  dark mode */
}

/* Toggle Button */
.theme-toggle-btn {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: #3498db;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  margin: 15px 0;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.theme-toggle-btn:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.theme-toggle-btn:active {
  transform: translateY(1px);
}

/* for the form styling  */
form {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

input[type="date"],
input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 200px;
}

input[type="date"]:focus,
input[type="text"]:focus {
  border-color: #3498db;
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.3);
}

button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: #3498db;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

button:active {
  transform: translateY(1px);
}

.controls {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.error-message {
  color: #d9534f;
  margin-top: 20px;
  font-weight: bold;
  background-color: #f8d7da;
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  width: 60%;
  margin: 20px auto;
  animation: fadeIn 0.5s ease-in-out;
}

.apod-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.apod-container {
  text-align: left;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  overflow: hidden;
  animation: slideUp 0.5s ease;
}

.apod-container:hover {
  transform: translateY(-5px);
}

.apod-container img,
.apod-container iframe {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}

.apod-container h2 {
  color: #2c3e50;
  font-size: 1.5rem;
  margin: 15px;
  text-align: center;
}

.apod-container p {
  line-height: 1.6;
  font-size: 1rem;
  padding: 0 15px 15px;
  color: #555;
}

.image-date {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  color: #555;  /* for Light mode */
  margin: 10px 0;
}

.App.dark .image-date {
  color: #ffffff;  /* for Dark mode */
}
/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .controls {
    flex-direction: column;
  }

  .error-message {
    width: 90%;
  }
}
